//actions & payloads for notifications
export const types = {
  DISPLAY_SNACKBAR_MESSAGE: "DISPLAY_SNACKBAR_MESSAGE",
  HANDLE_SNACKBAR_CLOSE: "HANDLE_SNACKBAR_CLOSE"
};

export const hideNotification = () => ({
  type: types.HANDLE_SNACKBAR_CLOSE
});

export const showNotification = (message, notificationType, update) => ({
  type: types.DISPLAY_SNACKBAR_MESSAGE,
  message,
  notificationType,
  update
});