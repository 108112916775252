import React, {useState} from "react";
import {Link} from "react-router-dom";

//component and helper imports
import NavigationStyles from "../../assets/jss/components/NavigationStyles";
import acoerStamp from '../../assets/img/acoer-stamp.svg';

//material-ui components
import {withStyles} from "@material-ui/core/styles";
// import SearchIcon from '@material-ui/icons/Search';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Menu
  // InputBase
} from '@material-ui/core'

export const NavBar = props => {
  const {classes} = props;
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position={"absolute"}
      color={"default"}
      className={classes.overRideShadow}
    >
      <Toolbar>
        <Grid container alignItems="center" justify="space-between">
          <Grid item md={4} sm={6} xs={9}>
            <Link to={"/"} className={classes.link}>
              <Grid container direction={'row'} alignItems="center">
                <img src={acoerStamp} alt="acoer stamp" height={110}/>
                <Grid item className={classes.headerContainer}>
                  <Typography variant='body1' id='first'>
                    Our
                  </Typography>
                  <Typography variant='body1' id='second'>
                    Blockchain
                  </Typography>
                  <Typography variant='body1' id='third'>
                    Family
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item md={3} xl={4} className={classes.desktop}></Grid>
          <Grid item lg={1} className={classes.desktop}></Grid>
          <Grid item className={classes.desktop}>
            <Link to={"/"} className={classes.link}>
              <Typography variant='h1'>Home</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.desktop}>
            <Link to={"/about"} className={classes.link}>
              <Typography variant='h1'>About</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.desktop}>
            <a
              href={'http://www.acoer.com'}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <Typography variant='h1' id="link">
                Acoer.com
              </Typography>
            </a>
          </Grid>
          {/* Search Bar! */}
          {/* <Grid item className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Grid> */}
          <Grid item className={classes.mobile}>
            <IconButton
              aria-label="more"
              aria-controls="nav-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuRoundedIcon/>
            </IconButton>
            <Menu
              id="nav-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                style: {
                  width: '20ch',
                },
              }}
            >
              <Link to={"/"} className={classes.link}>
                <Typography variant='h1'>Home</Typography>
              </Link>
              <Link to={"/about"} className={classes.link}>
                <Typography variant='h1'>About</Typography>
              </Link>
              <a
                href={'http://www.acoer.com'}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Typography variant='h1' id="link">
                  Acoer.com
                </Typography>
              </a>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(NavigationStyles)(NavBar);
