// import { fade } from '@material-ui/core/styles'

const ImageStyles = theme => ({
  image: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '8px 8px 10px rgba(230,70,97,.30)'
    }
  },
  modal: {
    '& div:focus': {
      outline: 'none'
    },
    '& div': {
      width: '100vw',
      textAlign: 'center',
      backgroundColor: 'white',
      marginTop: '5vh',
      '& img': {
        height: '90vh',
        maxWidth: '90vw'
      },
      '& button': {
        position: 'absolute',
        marginTop: '10px'
      }
    }
  }
});

export default ImageStyles;
