import React, {useState} from 'react';
import moment from 'moment';

//components
import ImageStyles from '../../assets/jss/components/ImageStyles';

//material-ui
import {withStyles} from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  Grid,
  Typography,
  Modal,
  IconButton
} from '@material-ui/core';

const ImageContainer = (props) => {
  const {
    classes,
    path,
    i
  } = props;
  const [open, setOpen] = useState(false);

  let date = path.split('/')[3].split('.')[0];
  let formattedDate = moment(date, "YYYY-MM-DD").format("dddd, MMMM Do, YYYY");

  const handleClick = () => {
    if (document.body.offsetWidth > 741) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <Grid container justify={'center'}>
      <Grid item xs={12}>
        <Typography variant='caption' style={{padding: 20}}>
          {formattedDate || ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img
          className={classes.image}
          src={`${path}`}
          alt={`comic${i}`}
          onClick={handleClick}
        />
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
        onEscapeKeyDown={handleClose}
        className={classes.modal}
      >
        <div>
          <img
            src={`${path}`}
            alt={`comic${i}`}
          />
          <IconButton onClick={handleClose}>
            <CloseRoundedIcon/>
          </IconButton>
        </div>
      </Modal>
    </Grid>
  )
}

export default withStyles(ImageStyles)(ImageContainer);