import React from "react";

//component and styling imports
import Home from '../components/Home/Home';

//material-ui
import { Grid } from "@material-ui/core";

const HomeView = () => {
  return (
    <Grid container justify={'center'}>
      <Grid item xs={12}>
        <Home />
      </Grid>
    </Grid>
  );
};

export default HomeView;