import React from 'react'

//components
import logo from '../../assets/img/acoer_logo_wb_rgb_2020.png'

//material-ui
import { withStyles } from '@material-ui/core/styles'
import AboutStyles from '../../assets/jss/components/AboutStyles'
import { 
  Grid,
  Typography
} from '@material-ui/core'

const About = (props) => {
  const { classes } = props

  return (
    <Grid container justify={'center'}>
      <Grid item xs={12} className={classes.textContainer}>
        <Typography variant={'h3'}>
          We consider ourselves professional techno-geeks.
        </Typography>
        <Typography>
          That's why we created Our Blockchain Family.
        </Typography>
        <Typography>
          We're taking the scenic tour to all roads blockchain and hope you'll join us!
        </Typography>
        <Typography variant={'h4'}>
          Subscribe and enjoy!
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.imageContainer}>
        <Typography>
          Built By:
        </Typography>
        <img src={logo} alt={'acoer logo'} />
      </Grid>
    </Grid>
  )
}

export default withStyles(AboutStyles)(About)