import { fade } from '@material-ui/core/styles'

const NavigationStyles = theme => ({
  overRideShadow: {
    boxShadow: "none",
    borderBottom: '1px solid #E0E0E2',
    backgroundColor: "#fff",
    maxHeight: '100px'
  },
  headerLogoContainer: {
    width: '320px',
    '@media screen and (max-width: 372px)': {
      width: '70vw'
    },
  },
  headerContainer: {
    '& p': {
      fontWeight: '900',
      fontSize: '20px',
      fontFamily: 'monospace',
      letterSpacing: '.75px',
      color: '#2c2c2c',
      '&#first': {
        marginBottom: '0px'
      },
      '&#second': {
        margin: '-3px 0px'
      },
      '&#third': {
        marginTop: '0px'
      },
      '@media screen and (max-width: 372px)': {
        fontSize: '16.5px !important'
      },
    },
  },
  desktop: {
    '@media screen and (max-width: 741px)': {
      display: 'none'
    },
  },
  mobile: {
    '@media screen and (max-width: 740px)': {
      display: 'visible'
    },
    '@media screen and (min-width: 742px)': {
      display: 'none'
    }
  },
  link: {
    textDecoration: "none",
    color: "#000",
    maxWidth: 'fit-content',
    '& img': {
      maxHeight: '175px',
      maxWidth: '39vw',
      '@media screen and (max-width: 740px)': {
        marginLeft: '-5vw'
      },
    },
    '& h1': {
      fontSize: 'larger',
      padding: '20px',
      margin: '0px 5px',
      fontWeight: '400',
      fontFamily: '"Poppins", sans-serif',
      '&:hover': {
        backgroundColor: fade('#ececec', 0.45)
      },
    }
  },
  search: {
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: fade('#ececec', 0.25),
    '&:hover': {
      backgroundColor: fade('#ececec', 0.45)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  footer: {
    marginBottom: '25px',
    '@media screen and (max-width: 470px)': {
      fontSize: '3vw',
    },
    '& p#link': {
      '@media screen and (max-width: 470px)': {
        fontSize: '3vw',
        marginTop: '24px'
      },
    }
  },
  divider: {
    width: '100%', 
    marginTop: '50px'
  }
});

export default NavigationStyles;
