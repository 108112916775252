import React from 'react'

//components
import About from '../components/About/About'

//material-ui
import { Grid } from '@material-ui/core'

const AboutView = () => {
  return (
    <Grid container justify={'center'}>
      <Grid item xs={12}>
        <About />
      </Grid>
    </Grid>
  )
}

export default AboutView