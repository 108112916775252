import React from 'react';
import { Router, Switch, Route } from "react-router-dom";

// Redux
import history from "./components/Shared/Redux/history";

// components
import NavBar from "./components/Navigation/NavBar";
import Footer from './components/Navigation/Footer';
import HomeView from "./views/HomeView";
import AboutView from './views/AboutView';
import theme from './styles/theme';

// MUI
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const App = () => {
  return (
    <div className="App">
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline/>
          <NavBar />
          <div style={{marginTop: '60px'}}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (<HomeView {...props} />)}
              />
              <Route
                exact
                path="/about"
                render={(props) => (<AboutView {...props} />)}
              />
            </Switch>
          </div>
          <Footer />
        </MuiThemeProvider>
      </Router>
    </div>
  );
}

export default App;
