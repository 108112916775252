import React from 'react';

//components
import ImageContainer from './ImageContainer'

//material-ui
import {
  Grid
} from "@material-ui/core";

export const Home = () => {
  //gets all svg files from comics folder
  const reqSvgs = require.context('../../assets/img/comics', true, /\.svg$/)
  //gets each images path name
  const paths = reqSvgs.keys()
  //passes each path into reqSvgs to get a file path
  const svgs = paths.map(path => reqSvgs(path))

  return (
    <Grid container justify={'center'} spacing={6} style={{margin: 0, width: '100%',}}>
      {svgs.reverse().map((path, i) => (
        <Grid item xs={12} md={7}>
          <ImageContainer key={i} i={i} path={path}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default Home;
