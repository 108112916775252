import { types } from "./NotificationsActions";

//reducers on actions for notifications
export const initialState = {
  message: null,
  visible: false,
  notificationType: "info"
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case types.DISPLAY_SNACKBAR_MESSAGE: {
      return {
        ...state,
        message: action.message,
        visible: true,
        notificationType: action.notificationType,
        update: action.update
      };
    }

    case types.HANDLE_SNACKBAR_CLOSE: {
      return {
        ...state,
        message: "",
        visible: false
      };
    }

    default:
      return state;
  }
}