import React from 'react';
import ReactDOM from 'react-dom';

//components
import App from './App';
import registerServiceWorker from "./serviceWorker";

//redux
import { Provider } from "react-redux";
import { defaultStore } from "./components/Shared/Redux/stores/defaultStore";

//styling
import './styles/index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={defaultStore}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();