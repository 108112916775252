// import { fade } from '@material-ui/core/styles'

const AboutStyles = theme => ({
  textContainer: {
    textAlign: 'center',
    marginTop: '50px',
    maxWidth: '620px',
    padding: '10px',
    '& h3': {
      marginBottom: '25px'
    },
    '& p': {
      fontSize: 'large'
    },
    '& h4': {
      marginTop: '25px',
      fontSize: '30px'
    }
  },
  imageContainer: {
    marginTop: '50px',
    textAlign: 'center'
  }
});

export default AboutStyles;
