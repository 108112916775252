import React from 'react'

//components
import NavigationStyles from "../../assets/jss/components/NavigationStyles";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Divider
} from '@material-ui/core'

const Footer = (props) => {
  const { classes } = props

  return (
    <Grid 
      container 
      justify="center" 
      className={classes.footer}
    >
      <Divider light className={classes.divider}/>
      <Grid item style={{marginTop: '25px'}}>
        © 2020-2021 Acoer, LLC All Rights Reserved.
      </Grid>
    </Grid>
  )
}

export default withStyles(NavigationStyles)(Footer);